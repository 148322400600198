exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-candidates-js": () => import("./../../../src/pages/candidates.js" /* webpackChunkName: "component---src-pages-candidates-js" */),
  "component---src-pages-client-js": () => import("./../../../src/pages/client.js" /* webpackChunkName: "component---src-pages-client-js" */),
  "component---src-pages-community-js": () => import("./../../../src/pages/community.js" /* webpackChunkName: "component---src-pages-community-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-recruitment-services-js": () => import("./../../../src/pages/recruitment-services.js" /* webpackChunkName: "component---src-pages-recruitment-services-js" */),
  "component---src-pages-temp-jobs-js": () => import("./../../../src/pages/temp-jobs.js" /* webpackChunkName: "component---src-pages-temp-jobs-js" */),
  "component---src-templates-job-js-content-file-path-opt-build-repo-static-jobs-245726-md": () => import("./../../../src/templates/job.js?__contentFilePath=/opt/build/repo/static/jobs/245726.md" /* webpackChunkName: "component---src-templates-job-js-content-file-path-opt-build-repo-static-jobs-245726-md" */),
  "component---src-templates-job-js-content-file-path-opt-build-repo-static-jobs-733494-md": () => import("./../../../src/templates/job.js?__contentFilePath=/opt/build/repo/static/jobs/733494.md" /* webpackChunkName: "component---src-templates-job-js-content-file-path-opt-build-repo-static-jobs-733494-md" */)
}

